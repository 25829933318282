import styled from 'styled-components';
import CustomContainer from '../components/custom-container';
import CustomNavbar from '../components/custom-navbar';

const CustomDiv = styled.div`
  ol {
    line-height: 1.75rem;
  }
  ol > li {
    padding-bottom: 20px;
    font-size: 1.15rem;
  }
  ol > li > ul {
    font-size: 1rem;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Accent = styled.span`
  color: #234b7e;
  font-weight: bold;
`;

export default function Terms() {
  return (
    <CustomDiv>
      <CustomNavbar hideSearchBar={true} />
      <CustomContainer>
        <div
          style={{
            paddingTop: '40px',
            paddingLeft: '10px',
            paddingRight: '10px',
            margin: '0 auto',
            maxWidth: '900px',
          }}
        >
          <h1
            style={{
              fontWeight: '900',
              fontSize: '30px',
              textAlign: 'center',
              background: 'white',
              color: '#1c3669',
              borderTop: '1px solid #1c3669',
              borderBottom: '1px solid #1c3669',
              padding: '10px',
            }}
          >
            개인정보 수집·이용 및 제공에 대한 안내
          </h1>
          <div
            style={{
              padding: '3px',
              border: '1px solid #c4c7ce',
              borderRadius: '5px',
              marginBottom: '20px',
            }}
          >
            <div
              style={{
                padding: '15px 20px',
                border: '1px solid #c4c7ce',
                borderRadius: '5px',
              }}
            >
              소상공인방송정보원에서는{' '}
              <Accent>정책정보, 교육 콘텐츠 제공 및 서비스 개선</Accent>을
              위하여 다음의 정보를 수집하고 있습니다.
              <br /> 가입정보에 대한 선택정보를 입력하지 않은 경우에도 서비스
              이용에는 제한이 없습니다.
              <br />(
              <Accent>
                단, 정보미기재 시 교육 콘텐츠 및 맞춤형 정보제공 서비스 이용에
                제한
              </Accent>
              이 있을 수 있습니다.)
            </div>
          </div>
          <ol>
            <li>
              개인정보의 수집·이용에 관한 사항
              <ul>
                <li>
                  <Bold>(수집·이용 목적)</Bold>
                  <br /> 전통시장 및 상점가 정책정보 • 이슈 안내, 교육 콘텐츠 등
                  정보제공서비스 및 회원관리(이용자 식별, 교육 이수율 확인 등)
                </li>
                <li>
                  <Bold>(개인정보의 수집항목)</Bold>
                  <br /> 소속 시장(상점가) 혹은 기관명, 이름, 연락처, 출생연도
                </li>
                <li>
                  <Bold>(보유 및 이용 기간)</Bold>
                  <br /> 동의일로부터 탈퇴시까지 보유 • 이용
                </li>
                <li>
                  <Bold>
                    (동의를 거부할 권리 및 동의를 거부할 경우의 불이익)
                  </Bold>
                  <br /> 사용자는 동의를 거부할 권리가 있으며, 동의를 거부할
                  경우 “상인정보통(정보제공서비스)" 이용이 제한될 수 있습니다.
                </li>
              </ul>
            </li>
            <li>
              수집한 개인정보의 제3자 제공에 관한 사항
              <ul>
                <li>
                  <Bold>(제공받는 자)</Bold>
                  <br /> 중소벤처기업부, 소상공인시장진흥공단
                </li>
                <li>
                  <Bold>(제공받는 자의 개인정보 이용 목적)</Bold>
                  <br /> 전통시장 및 상점가 정책정보 • 이슈 등 정보제공서비스 및
                  회원관리 (이용자식별, 서비스 가입의사 확인 등)
                </li>
                <li>
                  <Bold>(제공하는 개인정보 항목)</Bold>
                  <br /> 소속 시장(상점가) 혹은 기관명, 이름, 연락처, 출생연도
                </li>
                <li>
                  <Bold>(제공받는 자의 개인정보 보유 및 이용 기간)</Bold>
                  <br /> 동의일로부터 탈퇴시까지 보유 • 이용
                </li>
                <li>
                  <Bold>
                    (동의를 거부할 권리 및 동의를 거부할 경우의 불이익)
                  </Bold>
                  <br /> 개인정보 제공자는 개인정보의 제3자 제공에 대한 동의를
                  거부할 권리가 있으나, 위 항목 동의 거부시
                  "상인정보통(정보제공서비스)"이용이 제한될 수 있습니다.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </CustomContainer>
    </CustomDiv>
  );
}
