import { useState, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import { useCookies } from 'react-cookie';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { VideoTab } from '@/enum/video';
import useFetchVideos from '@/hooks/video/useFetchVideos';
import isEmpty from '@/utils/isEmpty';
import Flex from '@/components/common/Flex';
import HashtagItem from '@/components/video/hashtag/Item';
import useFetch from '../hooks/use-fetch';
import { comma } from '../utils/comma';
import * as CssHelper from '../utils/css-helper';
import ScrollToTop from '../components/scroll-to-top';
import VideoList from '../containers/video-list';
import ViewMore from '../components/view-more';
import CustomNavbar from '../components/custom-navbar';
import CustomContainer from '../components/custom-container';
import StarRating from '../components/star-rating';
import VideoPlayer from '../components/video/video-player';
import LoginChecker from '../components/login-checker';
import NotFound from './not-found';

const Section = styled.div`
  margin: 0 10px;
  padding: 10px 0;
  border-bottom: 1px solid #dfdfdf;

  @media only screen and (min-width: ${CssHelper.BREAK_POINT_MOBILE}px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const DetailContainer = styled.div`
  margin-bottom: 15px;
`;

const Title = styled.span`
  display: block;

  font-weight: 500;
  font-size: 1.25rem;
`;

const SubContent = styled.div`
  margin-top: 5px;
  font-size: 1rem;
  color: #848c9c;
`;

const Date = styled.span`
  font-size: 0.95rem;
  font-weight: 500;
`;

const Description = styled.div`
  margin-top: 15px;
  color: #444c5c;
`;

const SectionTitle = styled.div`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 1.1rem;
`;

function Logger({ id, videoDuration, playbackTimeRef }) {
  const [prevPlaybackTime, setPrevPlaybackTime] = useState(0);
  const { trigger } = useFetch('put', `videos/watch/${id}/playback-time`, {
    lockBeforeTriggered: true,
    onError: () => {
      trigger({ playbackTime: playbackTimeRef.current });
    },
  });
  const [sent, setSent] = useState(false);

  useEffect(() => {
    setSent(false);
    setPrevPlaybackTime(0);
  }, [id]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log(
        playbackTimeRef?.current,
        playbackTimeRef.current === prevPlaybackTime,
        sent,
        videoDuration,
        videoDuration / 2 <= playbackTimeRef.current,
      );
      if (playbackTimeRef.current === prevPlaybackTime) return;
      setPrevPlaybackTime(playbackTimeRef.current);
      if (
        !!videoDuration &&
        ((videoDuration / 2 <= playbackTimeRef.current && !sent) ||
          (videoDuration - 5 <= playbackTimeRef.current &&
            videoDuration >= playbackTimeRef.current))
      ) {
        trigger({ playbackTime: playbackTimeRef.current });
        setSent(true);
      }
    }, 1000);
    return () => {
      trigger({ playbackTime: playbackTimeRef.current });
      clearInterval(intervalId);
    };
  }, [id, videoDuration, sent]);

  return <></>;
}

function View() {
  const { id } = useParams();
  const playbackTime = useRef(0);
  const [cookies] = useCookies();
  const [rating, setRating] = useState(0);
  const [watched, setWatched] = useState(false);
  const [videoRating, setVideoRating] = useState({ rating: 0, count: 0 });

  const { response } = useFetch('post', `videos/${id}/watch`);

  const { response: videoResponse, isLoading: isVideoRetriving } = useFetch(
    'get',
    `videos/${id}`,
    {
      onResponse: () => setWatched(false),
    },
  );

  const { isLoading: isRatingRetriving } = useFetch(
    'get',
    `videos/${id}/review/my`,
    {
      onResponse: (response) => {
        setRating(response.data?.data?.rating || 0);
      },
    },
  );

  const { isLoading: isRatingLoading, trigger: getVideoRating } = useFetch(
    'get',
    `videos/${id}/review/rate/average`,
    {
      onResponse: (response) => {
        setVideoRating({
          rating: response?.data?.data?.rating,
          count: response?.data?.data?.reviewCount,
        });
      },
    },
  );

  const { trigger: ratingTrigger } = useFetch('post', `videos/${id}/review`, {
    lockBeforeTriggered: true,
    onResponse: getVideoRating,
  });

  const { videos, videosLoading, videosMoreLoading, fetchNextPage } =
    useFetchVideos({
      size: 20,
      sort: 'kakaoId,desc',
      status:
        cookies?.type === 'organization-member'
          ? ''
          : VideoTab.UNWATCHED.toUpperCase(),
    });

  const { trigger: updateReviewTrigger } = useFetch(
    'put',
    `videos/${id}/review`,
    {
      lockBeforeTriggered: true,
      onResponse: getVideoRating,
    },
  );

  const handleRatingChange = useCallback(
    (ratingValue) => {
      setRating(ratingValue);

      if (Number.isInteger(rating) && rating > 0) {
        updateReviewTrigger({ rating: ratingValue });
      } else {
        ratingTrigger({ rating: ratingValue });
      }
    },
    [rating, ratingTrigger, updateReviewTrigger],
  );

  const video = videoResponse?.data || {};

  useBottomScrollListener(() => fetchNextPage(), {
    triggerOnNoScroll: true,
  });

  return (
    <div>
      <LoginChecker />
      <CustomNavbar hideShadow />
      <CustomContainer>
        <ScrollToTop />

        {!isVideoRetriving && !videoResponse ? (
          <NotFound content='영상을 찾을 수 없습니다.' />
        ) : (
          <div>
            <VideoPlayer
              video={video}
              isLoading={isVideoRetriving}
              onPlaybackTimeCount={(playTime) => {
                playbackTime.current = playTime;

                if (playTime >= video?.duration / 2) {
                  setWatched(true);
                }
              }}
            />

            <Logger
              id={response?.data.id}
              videoDuration={video?.duration}
              playbackTimeRef={playbackTime}
            ></Logger>

            <Section>
              <DetailContainer>
                <Title>{video.title || <Skeleton />}</Title>
                <SubContent>
                  <Date>
                    <FontAwesomeIcon
                      icon={faClock}
                      style={{ marginRight: '5px' }}
                    />
                    <span>
                      {!isVideoRetriving ? (
                        dayjs(video.createdAt).format('YYYY/MM/DD')
                      ) : (
                        <Skeleton width={80} />
                      )}
                    </span>
                  </Date>

                  {!isEmpty(videoResponse?.data?.hashtags) && (
                    <Flex
                      gap='0.625rem'
                      flexWrap='wrap'
                      margin='0.625rem 0 0 0'
                    >
                      {videoResponse.data.hashtags.map((hashtag) => (
                        <HashtagItem
                          key={hashtag.id}
                          {...hashtag}
                          selected={false}
                        />
                      ))}
                    </Flex>
                  )}

                  <Description>
                    {!isVideoRetriving ? (
                      <ViewMore text={video.description} />
                    ) : (
                      <Skeleton count={3} />
                    )}
                  </Description>
                </SubContent>
              </DetailContainer>
            </Section>

            {cookies?.type === 'organization-member' ? (
              <></>
            ) : (
              <Section>
                {/* <SectionTitle style={{marginBottom: 0}}>평점</SectionTitle> */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '7px',
                  }}
                >
                  {video?.watched || watched ? (
                    <div
                      className='d-inline-flex'
                      style={{ flexDirection: 'column' }}
                    >
                      {isRatingRetriving ? (
                        <Skeleton
                          width={100}
                          height={20}
                        />
                      ) : (
                        <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                          {video.rating
                            ? '내가 남긴 평점'
                            : '첫 평점을 남겨주세요!'}
                        </span>
                      )}
                      {isRatingRetriving ? (
                        <Skeleton
                          width={200}
                          height={40}
                        />
                      ) : (
                        <StarRating
                          value={rating}
                          onValueChanged={handleRatingChange}
                        />
                      )}
                    </div>
                  ) : (
                    <div style={{ fontWeight: 'bold', paddingRight: '10px' }}>
                      평점은 시청완료한 영상에 남길 수 있습니다.
                    </div>
                  )}

                  <div style={{ flex: 1 }}></div>

                  <div
                    style={{
                      display: 'inline-flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '80px',
                    }}
                  >
                    {isRatingLoading ? (
                      <Skeleton
                        width={50}
                        height={10}
                      />
                    ) : (
                      <span style={{ fontSize: '0.8rem', color: 'gray' }}>
                        평가 {comma(videoRating?.count) || 0}개
                      </span>
                    )}
                    <span style={{ fontSize: '1.8rem' }}>
                      {isRatingLoading ? (
                        <Skeleton
                          width={70}
                          height={25}
                        />
                      ) : videoRating?.rating ? (
                        Math.round(videoRating?.rating * 10) / 10 + '점'
                      ) : (
                        '없음'
                      )}
                    </span>
                    <StarRating
                      value={Math.round(videoRating?.rating)}
                      size={15}
                      readOnly
                    ></StarRating>
                    <span
                      style={{
                        color: 'gray',
                        fontSize: '0.8rem',
                        marginTop: '-5px',
                      }}
                    ></span>
                  </div>
                </div>
              </Section>
            )}

            <Section>
              {cookies?.type === 'organization-member' ? (
                <SectionTitle>영상 더보기</SectionTitle>
              ) : (
                <SectionTitle>미수강 영상들</SectionTitle>
              )}
              <VideoList
                size={20}
                videos={videos}
                videoLoaded={!videosLoading}
                isMoreComingIn={videosMoreLoading}
              />
            </Section>
          </div>
        )}
      </CustomContainer>
    </div>
  );
}

export default View;
