import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VideoThumbnail from './video-thumbnail';
import humanReadableDate from '../../utils/human-readable-date';

import '../../scss/Ellipsis.scss';
import '../../scss/Clearfix.scss';

dayjs.locale('ko');
dayjs.extend(relativeTime);

const Container = styled.div`
  display: block;
  font-size: 13px;

  margin-bottom: 5px;
  cursor: pointer;
`;

const InformationContainer = styled.div`
  float: left;
  margin-left: 10px;
  width: calc(65% - 10px);
`;

const Title = styled.span`
  font-weight: 500;
  color: #32353b;

  font-size: 0.95rem;
`;

const SubTitle = styled.span`
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0 0 2px;
  color: #848c9c;
`;

const Date = styled.span`
  margin-left: 5px;
`;

function VideoListItem({ video }) {
  const [cookies] = useCookies();

  return (
    <Container className='clearfix'>
      <VideoThumbnail
        title={video.title}
        url={video.thumbnailUrl}
        watched={video.watched && cookies?.type === 'merchant'}
        styleApplier={(element) => styled(element)`
          display: inline-block;
          float: left;
          max-width: 250px;
          width: 35%;
        `}
      />
      <InformationContainer>
        <Title
          className='ellipsis'
          title={video.title}
        >
          {video.title}
        </Title>
        <SubTitle>
          <FontAwesomeIcon icon={faClock} />
          <Date>{humanReadableDate(dayjs(video.createdAt))}</Date>
        </SubTitle>
      </InformationContainer>
    </Container>
  );
}

export default VideoListItem;
